<template>
  <div class="scroll-container">
    <CategoryTitle :category="category" />
    <div class="invoice-list pt-1" v-if="invoices.length > 0">
      <v-row class="header">
        <v-col cols="2"><strong>N&ordm;</strong></v-col>
        <v-col cols="4" sm="3"
          ><strong>{{ $t("invoice.date") }}</strong></v-col
        >
        <v-col cols="5" sm="4"
          ><strong>{{ $t("invoice.documentType") }}</strong></v-col
        >
        <v-col cols="1" sm="3"></v-col>
      </v-row>
      <v-row v-for="invoice in invoices" :key="invoice.dokuknoxID">
        <v-col cols="2">{{ invoice.attribDocumentNumber }}</v-col>
        <v-col cols="4" sm="3">{{ invoice.attribDocumentData }}</v-col>
        <v-col cols="5" sm="4">
          <template v-if="invoice.attribTypeOfDocument">{{
            $t(`dashboard.invoice.type.${invoice.attribTypeOfDocument}`)
          }}</template>
        </v-col>
        <v-col
          cols="1"
          :class="$vuetify.breakpoint.xs ? 'd-block px-0' : 'd-none'"
          ><a
            :href="invoice.dokuknoxDownloadLink"
            target="_blank"
            @click="downloadDocument(invoice.attribDocumentNumber)"
            ><v-icon color="primary">$download</v-icon></a
          ></v-col
        >
        <v-col
          cols="5"
          sm="3"
          :class="$vuetify.breakpoint.xs ? 'd-none' : 'd-block'"
          ><v-tooltip top>
            <template v-slot:activator="{ on, attrs }"
              ><a
                v-bind="attrs"
                v-on="on"
                :href="invoice.dokuknoxDownloadLink"
                target="_blank"
                class="text-decoration-none"
                @click="downloadDocument(invoice.attribDocumentNumber)"
                >{{ $t("invoice.downloadDocument") }}</a
              ></template
            >
            <span>{{ $t("invoice.downloadDocumentTip") }}</span>
          </v-tooltip></v-col
        >
      </v-row>
    </div>
    <v-card light outlined v-else class="pa-2 text-center w-100">
      <v-card-title class="headline">{{
        $t("invoice.noAvailableDocuments")
      }}</v-card-title>
    </v-card>
  </div>
</template>

<style scoped lang="scss">
.invoice-list {
  .row:nth-child(even) {
    background-color: var(--v-grey-lighten1);
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle";
import reload from "~/mixins/reload";
import login from "~/mixins/login";
import categoryMixin from "~/mixins/category";
import downloadDocument from "~/mixins/downloadDocument";
import CustomService from "@/service/customService";

export default {
  name: "Invoice",
  data() {
    return {
      invoices: []
    };
  },
  mixins: [reload, login, categoryMixin, downloadDocument],
  components: { CategoryTitle },

  computed: {},
  methods: {
    downloadDocument(productId) {
      var url = window.event.currentTarget.href;
      this.handleReceiptDownload(url, productId);
    },
    reload() {
      CustomService.getInvoiceList(
        this.$dayjs()
          .subtract(4, "month")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        this.$dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      ).then(data => {
        this.invoices = data.dokuknoxInvoiceList;
        this.invoices.sort((a, b) => b.dokuknoxID - a.dokuknoxID);
      });
    }
  },
  created() {
    this.reload();
  }
};
</script>
